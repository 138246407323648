@import url("https://fonts.googleapis.com/css?family=Average|Noto+Sans|Fjalla+One");

.current {
  -webkit-box-align: center;
  -webkit-box-pack: center;
  display: -webkit-box;

  z-index: 100;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex;
  color: black;
  width: 200px;
  height: 30px;
  padding: 12px;
  margin-top: 40px;
  margin-left: 60px;
  border-radius: 50px;

  vertical-align: middle;
  display: flex;
  flex-direction: row;
  justify-content: flex;
  align-items: center;
  opacity: 1;

  transform: scale(1.1);
  /*
  background-image: linear-gradient(
    to right,
    rgb(5, 223, 89) 0%,
    rgb(2, 168, 168) 100%
  );
  */
  background: white;

  box-shadow: 0px 0px 80px black;
}

.currentTitleLabel {
  font-size: 12px;
  font-weight: 200;
}

.currentSong {
  font-size: 14px;
}
/* 
.currentBubble {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: rgb(247, 247, 247);
  margin-right: 16px;
  box-shadow: 0px 0px 10px rgb(216, 216, 216);
}
 */