@import url(https://fonts.googleapis.com/css?family=Average|Noto+Sans|Fjalla+One|Roboto);
@import url(https://fonts.googleapis.com/css?family=Average|Noto+Sans|Fjalla+One);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: black;
  font-family: "Roboto", sans-serif;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100%;
  margin: auto;
}

.lineContaineContainer {
}

.lineContainer {
  z-index: 31;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  justify-content: flex;
  align-items: center;
  grid-template-columns: 60px 60px;
  background-color: rgb(34, 34, 34);
  opacity: 0.95;
  font-weight: regular;
  color: white;
  width: 1024px;
  margin: auto;

  text-align: left;

  mix-blend-mode: screen;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.wichtig {
  top: 50px;
  left: 20px;
  width: 2000px;
  background-color: blue;
  color: white;
  z-index: 211;
  font-size: 600px;
}

.lineContainer:hover {
  /* background-image: linear-gradient(
    to right,
    rgb(5, 223, 89) 0%,
    rgb(2, 168, 168) 100%
  ); */
  background-color: white;
  color: black;
  /* transform: scale(1.1); */
}

.youtubeButton {
  display: inline;
  background-image: url(/static/media/play-button.30d939d5.svg);
  background-size: 26px;
  background-position: center;
  background-repeat: no-repeat;

  color: rgb(77, 77, 77);
  border-radius: 30px;
  padding: 10px;
  margin-right: 16px;
  min-height: 16px;
  min-width: 16px;
  border: none;
  font-size: 10px;
  justify-content: left;
}

.youtubeButton:hover {
  background-color: white;
}

.seperatedString {
  flex: 3 1;
  z-index: 2;
  font-size: 13px;
  display: inline;

  margin-right: 16px;

  text-transform: inherit;
}

.moreOptions {
  display: inline;
  background-color: rgb(24, 24, 24);
  border-radius: 30px;
  padding: 10px;
  margin-right: 15px;
  min-height: 25px;
  min-width: 25px;
  border: none;
  font-size: 10px;
}

.moreOptions:hover {
  background-color: white;
}

.isImportantButton {
  height: 15px;
  margin-right: 10px;
  width: 15px;
  border-radius: 10px;
  border: none;
  background-color: rgb(44, 219, 231);
  /* background-image: url(./star.svg); */
  background-size: 10px;
  background-position: center;
  background-repeat: no-repeat;
  color: rgb(161, 161, 161);
}

.Title {
  z-index: -220;
  color: white;
  text-align: right;
  position: fixed;
  right: 28px;
  top: 50px;
  font-size: 160px;
  line-height: 160px;
  max-width: 500px;
  font-weight: 500;
  font-family: Fjalla One, sans-serif;
  margin: 20px;
}

.App-link {
  color: #61dafb;
}

.blubgray {
  position: fixed;
  z-index: 0;
  min-width: 150vh;
  min-height: 150vh;
  right: -75vh;
  bottom: -75vh;
  background: rgb(63, 183, 251);
  background: radial-gradient(
    circle,
    rgb(5, 223, 89) 0%,
    rgb(0, 162, 255) 100%
  );
  -webkit-animation: aura 40s infinite ease-in-out both;
          animation: aura 40s infinite ease-in-out both;
  mix-blend-mode: screen;
}

@-webkit-keyframes aura {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }

  14% {
    border-radius: 45% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 51% 39% 55% 45% / 50% 47% 62% 39%;
  }
  56% {
    /* transform: rotate(70deg); */
    border-radius: 61% 39% 58% 33% / 51% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 55% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
}

@keyframes aura {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }

  14% {
    border-radius: 45% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 51% 39% 55% 45% / 50% 47% 62% 39%;
  }
  56% {
    /* transform: rotate(70deg); */
    border-radius: 61% 39% 58% 33% / 51% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 55% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
}

@media only screen and (max-width: 600px) {
  .blubgray {
    max-width: 7vh;
    max-height: 7vh;
  }
}

.current {
  -webkit-box-align: center;
  -webkit-box-pack: center;
  display: -webkit-box;

  z-index: 100;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex;
  color: black;
  width: 200px;
  height: 30px;
  padding: 12px;
  margin-top: 40px;
  margin-left: 60px;
  border-radius: 50px;

  vertical-align: middle;
  display: flex;
  flex-direction: row;
  justify-content: flex;
  align-items: center;
  opacity: 1;

  -webkit-transform: scale(1.1);

          transform: scale(1.1);
  /*
  background-image: linear-gradient(
    to right,
    rgb(5, 223, 89) 0%,
    rgb(2, 168, 168) 100%
  );
  */
  background: white;

  box-shadow: 0px 0px 80px black;
}

.currentTitleLabel {
  font-size: 12px;
  font-weight: 200;
}

.currentSong {
  font-size: 14px;
}
/* 
.currentBubble {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: rgb(247, 247, 247);
  margin-right: 16px;
  box-shadow: 0px 0px 10px rgb(216, 216, 216);
}
 */
.youtube{
    z-index: 50;
    position: fixed;
left: 50%;
-webkit-transform: translate(-50%, -0%);
        transform: translate(-50%, -0%);
    max-width: 1024px;

}
